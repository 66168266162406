<template>
    <section class="q-desktop-section"
        style="padding-top: 0px !important; padding-bottom: 0px !important; background-color: #f2f2f7">
        <div class="q-layout-container" style="padding-top: 8px !important; padding-bottom: 8px !important">
            <div class="q-layout-row">
                <div class="q-col-lg-5 q-col-md-12 q-col-xl-5 q-col-sm-12 q-display-flex-column q-flex-center-items q-center"
                    style="background-color: transparent; padding: 32px">
                    <span class="q-dirt q-throw q-animatable" id="q-arch-triangle"
                        style="width: 20px !important; left: 90%; top: 0%; height: auto">
                        <img src="./../assets/images/dirt-cross.svg" alt="dirt" style="width: 40px" />
                    </span>
                    <div>
                        <img style="border-radius: 42px" src="../assets/images/nexen_team.jpg" alt="nexen_team" />
                    </div>
                </div>
                <div class="q-col-lg-7 q-col-md-12 q-col-xl-7 q-col-sm-12 q-display-flex-column q-align-stretch q-flex-center-items q-center"
                    style="background-color: transparent;padding-left: 32px;">
                    <h1 style="">
                        Join the <span class="gw-hightlight">future</span> of Financial Institutions` banking.
                    </h1>
                    <p style="margin: 0">
                        Empower your financial Institutions with seamless efficiency and innovation
                    </p>
                    <div class="q-button-group q-display-flex-row">
                        <a style="margin: 0 !important; margin-top: 32px !important" href="/contactSales"
                            class="q-button-fill q-button q-button-large elevate-1 q-download-file-button q-display-flex flow-center q-center"><span>Book
                                a demo</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'BookADemoSection',
}   
</script>
