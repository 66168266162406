<template>
  <div class="q-faq-container q-display-flex-column q-center flosw-center" @click="toggle()">
    <div class="q-faq-header q-display-flex-row q-align-stretch">
      <h3>{{ title }}</h3>
      <span class="q-display-flex-column q-center flow-center">
        <i ref="icon" class="ri-arrow-down-s-line"></i>
      </span>
    </div>
    <div ref="content" class="q-faq-content-section q-animatable" style="display: none">
      <p>
        {{ description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FaqComponent',
  props: ['description', 'title'],
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle: function () {

      var openIcon = 'ri-arrow-down-s-line'
      var closeIcon = 'ri-arrow-up-s-line'
      this.isOpen = !this.isOpen
      this.$refs.content.style.display = this.isOpen ? 'flex' : 'none'
      if (this.isOpen) {
        this.$refs.icon.classList.remove(openIcon)
        this.$refs.icon.classList.add(closeIcon)
      } else {
        this.$refs.icon.classList.add(openIcon)
        this.$refs.icon.classList.remove(closeIcon)
      }
    }
  }
}
</script>
<style scoped>
.q-faq-header,
.q-faq-container {
  width: 100%;
}

.q-faq-header h3 {
  width: 95%;
  font-size: 1.2em;

}

.q-faq-header {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, .12)
}

.q-faq-header span {
  width: 5%;
  height: 100%;
}

.q-faq-header span i {
  font-size: 1.5em;
}

.q-faq-content-section {
  height: auto;
}

@media (min-width: 992px) {
  .q-faq-header {
    padding-top: 8px;
    padding-bottom: 8px;

  }

  .q-faq-header h3 {
    font-size: 1.5em;
  }
}
</style>
