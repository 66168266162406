<template>
  <section class="q-hero-section q-grayed-backgroud" style="padding-bottom: 0px !important">
    <div class="q-section">
      <div class="q-layout-container">
        <div class="q-layout-row">
          <div class="q-col-sm-12 q-col-md-12 q-col-lg-5 q-col-xl-5 q-display-flex-column flow-center hero-container">
            <span class="q-dirt q-swing q-animatable" id="q-arch-triangle"
              style="width: 20px !important; top: 0%; height: auto">
              <img src="./../assets/images/dirt-cross.svg" alt="triangle" style="width: 20px" />
            </span>
            <span class="q-dirt q-hide-on-small-screens q-throw q-dirt-arch q-run q-animatable" id="q-arch-dirt"
              style="width: 40px !important; height: auto; top: 90% !important; right: 5%">
              <img src="./../assets/images/dirt-triangle.svg" alt="arch" style="width: 40px" />
            </span>
            <h1 class="subtext">
              <span class="gw-hightlight">Seamless </span>Banking, <span class="gw-hightlight"> Happy </span> Customers

            </h1>
            <p class="subtext">
              Imagine an ecosystem that enables you to have a 360 degree end to end overview of your business
            </p>
            <div class="q-button-group q-display-flex-row" style="width: 100%">
              <a style="margin: 0 !important; margin-top: 32px !important" href="/contactSales"
                class="q-button-fill q-button q-button-large elevate-1 q-download-file-button q-display-flex flow-center q-center"><span
                  style="">Book
                  a Demo</span>
              </a>
            </div>
          </div>
          <div class="q-col-sm-12 q-col-md-12 q-col-lg-7 q-col-xl-7 q-display-flex-column flow-center">
            <img class="hero-img" src="./../assets/images/intro_activity_log.svg" alt="activity log" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="gw_main_feature_section"
    class=" padding-top-0 padding-top-bottom q-desktop-section q-feature-container-section">
    <div class="q-section">
      <!--controls-->
      <div class="q-layout-container">
        <div class="q-layout-row padding-all-0 ">
          <span class="q-dirt q-swing q-animatable" id="q-arch-triangle"
            style="width: 20px !important; top: 0%;right: 10%; height: auto">
            <img src="./../assets/images/dirt-cross.svg" alt="triangle" style="width: 20px" />
          </span>

          <div class=" padding-all-0 q-col-sm-12 q-col-md-12 q-col-lg-12 q-col-xl-12 q-display-flex-column flow-center">
            <span class="q-dirt q-run q-animatable" id="q-arch-triangle"
              style="width: 20px !important; top: 0%; height: auto">
              <img src="./../assets/images/dirt-square.svg" alt="triangle" style="width: 20px" />
            </span>
            <h1 class="" style="color: white; width: 100%;text-align: center"> <span class="gw-hightlight">Why</span>
              Nexen
            </h1>
            <p class="" style="color: white;text-align: center;width: 70%;">
              At Nexen Tech, we have developed a product that enables our clients to conveniently run thier financial
              institutions and serve their customers or members seamlessly.
            </p>
          </div>

        </div>
      </div>

      <div class="q-layout-container  "
        style=" padding-top: 0px !important; padding-bottom: 0px !important; display: none" ref="saccos_features"
        id="gw_feature_item-0">
        <span class="q-dirt q-shake q-animatable" id="q-arch-triangle"
          style="width: 30px !important; top: 0%; left: 32%; height: auto">
          <img src="./../assets/images/dirt-snake.svg" alt="triangle" style="width: 30px" />
        </span>
        <div class="q-layout-row" style="padding: 0px">
          <!--Left control-->

          <!--Tabs-->
          <div class="q-col-12 padding-all-0 q-display-flex-column" style="background-color: transparent">
            <div class="q-layout-container q-feature-container">
              <div class="q-layout-row" style="padding: 0px">
                <div
                  class="q-col-lg-4 q-col-md-12 q-col-xl-4 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">
                  <CustomFeatureComponent themeClass="q-is-Light" icon="nexen-icons-5-179x179.png" title="
Reliable Reporting"
                    description="Our System tracks all business activities and creates audit trails for any transaction posted. We have a variety of reporting tools that enable our clients to make realtime and informed business decisions">
                  </CustomFeatureComponent>
                </div>
                <div
                  class="q-col-lg-4 q-col-md-12 q-col-xl-4 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">
                  <CustomFeatureComponent themeClass="q-is-Light" icon="nexen-icons-8-179x179.png"
                    title="Automated Business Operations"
                    description="Nexen Automates  all Operations and simplifies business governance with its innovative and cutting-edge software">
                  </CustomFeatureComponent>
                </div>
                <div
                  class="q-col-lg-4 q-col-md-12 q-col-xl-4 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">
                  <CustomFeatureComponent themeClass="q-is-Light" icon="nexen-icons-15-179x179.png" title="
Informed Business Growth"
                    description="Nexen  supports informed business growth in financial institutions by providing real-time data and advanced analytics. This enables better decision-making and personalized customer services. Nexen facilitates rapid product development and efficient operations, driving strategic growth and enhancing competitive advantage.">
                  </CustomFeatureComponent>
                </div>
              </div>
            </div>
            <div class="q-layout-container q-feature-container">
              <div class="q-layout-row" style="padding: 0px">
                <span class="q-dirt q-swing q-animatable" id="q-arch-circle"
                  style="width: 50px !important; top: 100%; left: 90%; height: auto">
                  <img src="./../assets/images/dirt-circle.svg" alt="dirt" style="width: 50px" />
                </span>
                <div
                  class="q-col-lg-4 q-col-md-12 q-col-xl-4 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">
                  <CustomFeatureComponent themeClass="q-is-Light" icon="nexen-icons-11-179x179.png" title="
Data Analysis Tools"
                    description="Nexen enhances data analysis in financial institutions through advanced tools that provide real-time insights and comprehensive reporting. These tools enable better decision-making, identify customer trends, and optimize operations. By leveraging data analytics, Nexen supports strategic planning, improves risk management, and drives business growth.">
                  </CustomFeatureComponent>
                </div>
                <div
                  class="q-col-lg-4 q-col-md-12 q-col-xl-4 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">
                  <CustomFeatureComponent themeClass="q-is-Light" icon="nexen-icons-14-179x179.png" title="
Client Self-Service"
                    description="Imagine being able to carry out all your banking activities at the comfort of your home or workstation better yet your farm.
Nexen offers client self-service tools, enabling customers to access their accounts, perform transactions, and manage finances 24/7 via online  platforms. Self-service tools provide convenience and empower clients to handle their banking needs independently.">
                  </CustomFeatureComponent>
                </div>
                <div
                  class="q-col-lg-4 q-col-md-12 q-col-xl-4 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">
                  <CustomFeatureComponent themeClass="q-is-Light" icon="nexen-icons-12-179x179.png" title="
Dedicated Support Team"
                    description="Nexen Tech  has a dedicated support team to ensure seamless operation and quick resolution of issues. This team provides expert assistance, maintenance, and troubleshooting, ensuring system reliability and optimal performance.">
                  </CustomFeatureComponent>
                </div>
              </div>
            </div>
            <div class="q-layout-container q-feature-container">
              <div class="q-layout-row" style="padding: 0px">

                <div
                  class="q-col-lg-4 q-col-md-12 q-col-xl-4 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">

                </div>
                <div
                  class="q-col-lg-4 q-col-md-12 q-col-xl-4 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">
                  <CustomFeatureComponent themeClass="q-is-Light" icon="nexen-icons-10-179x179.png"
                    title="Constant Innovation"
                    description="African Solutions for African Problems. Nexen is here to organize and improve workflow for financial Institutions. We continue to invest in R&D to ensure we create solutions that are realistic and tailored to solve our clients’ problems.">
                  </CustomFeatureComponent>
                </div>
                <div
                  class="q-col-lg-4 q-col-md-12 q-col-xl-4 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">

                </div>
              </div>
            </div>
          </div>
          <!--Right Control-->
        </div>
      </div>

      <div class="q-layout-container  q-feature-container-section"
        style="padding-top: 8px !important; padding-bottom: 0px !important; display: none" ref="individual_features"
        id="gw_feature_item-1">
        <div class="q-layout-row" style="padding: 0px">
          <!--Left control-->

          <!--Tabs-->
          <div class="q-tabs-container q-col-12 q-display-flex-column" style="background-color: transparent">
            <div class="q-layout-container q-feature-container">
              <div class="q-layout-row" style="padding: 0px">
                <div
                  class="q-col-lg-5 q-col-md-5 q-col-xl-5 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">
                  <CustomFeatureComponent themeClass="q-is-Light" icon="ri-team-line" title="Save with friends"
                    description="Join a SACCO or Microfinance today, or invite your friends to save with you.">
                  </CustomFeatureComponent>
                </div>
                <div
                  class="q-col-lg-7 q-col-md-7 q-col-xl-7 q-col-sm-12 q-display-flex-column q-flex-center-items q-center feature"
                  style="background-color: transparent">
                  <CustomFeatureComponent themeClass="q-is-Light" icon="ri-ai-generate" title="Get instant cheap loans"
                    description="Access the cheapest loans from licensed SACCOs and request guarantors digitally">
                  </CustomFeatureComponent>
                </div>
              </div>
            </div>
          </div>
          <!--Right Control-->
        </div>
      </div>

    </div>
  </section>

  <!--text section-->
  <section class="q-desktop-section" style="padding-top: 0px !important; padding-bottom: 0px !important">
    <div class="q-section q-has-shaded-bg">
      <!--Main tile-->

      <div class="q-layout-container" style="padding-top: 8px !important; padding-bottom: 8px !important">
        <div class="q-layout-row   ">
          <div
            class="   q-col-lg-12 q-col-md-12 q-col-xl-12 q-col-sm-12 q-display-flex-column q-flex-center-items q-align-self-center q-theme-text-container"
            style="background-color: transparent">
            <h1 class="q-center-h2 center-text" style="margin-bottom: 32px">
              We’ve got you <span class="gw-hightlight">covered</span>
            </h1>

            <p class="center-text q-center-sub-title-text" style="margin-bottom: 0; line-height: 2rem">
              The security of your data and the data of your members comes first. Access controls. 99.99% uptime.
            </p>
          </div>
          <span class="q-dirt q-shake q-animatable" id="q-arch-triangle"
            style="width: 30px !important; top: 10%; left: 10%; height: auto">
            <img src="./../assets/images/dirt-arch.svg" alt="dirt" style="width: 30px" />
          </span>
        </div>
        <div class="q-layout-row  padding-top-0  padding-bottom-0">
          <div
            class="   padding-bottom-0 q-col-lg-12 q-col-md-12 q-col-xl-12 q-col-sm-12 q-display-flex-column q-align-stretch q-flex-center-items q-center"
            style="background-color: transparent">

          </div>
        </div>
      </div>
      <!--controls-->
    </div>
  </section>

  <!--text section-->
  <section class="q-desktop-section q-light-bg-container"
    style="padding-top: 0px !important; padding-bottom: 0px !important">
    <div class="q-section q-has-shaded-bg">
      <!--Main tile-->

      <div class="q-layout-container" style="padding-top: 8px !important; padding-bottom: 8px !important">
        <div class="q-layout-row   ">
          <div
            class="q-col-lg-12 q-col-md-12 q-col-xl-12 q-col-sm-12 q-display-flex-column q-flex-center-items q-align-self-center q-theme-text-container"
            style="background-color: transparent">
            <h1 class="q-center-h2 center-text" style="">
              Our Model
            </h1>

            <p class="center-text q-center-sub-title-text" style="margin-bottom: 0; line-height: 2rem">
              Unleash your creativity and sew your way to endless possibilities.
            </p>
          </div>

        </div>
        <div class="q-layout-row  padding-top-0  padding-bottom-0">
          <div
            class="   padding-top-0 q-col-lg-12 q-col-md-12 q-col-xl-12 q-col-sm-12 q-display-flex-column flow-center q-center"
            style="background-color: transparent">
            <img class="model-img" src="../assets/images/nexen-icons-19-1916x573.jpg" alt="our model">
          </div>
        </div>
      </div>
      <!--controls-->
    </div>
  </section>


  <section class="q-desktop-section " style="">
    <div class="q-layout-container padding-top-0 padding-bottom-0"
      style="padding-top: 8px !important; padding-bottom: 8px !important">
      <FaqsComponentSection :faqs="testimonials" title="Incase you missed anything"></FaqsComponentSection>

    </div>

  </section>
  <!--text section-->
  <section class="q-desktop-section " style="padding-top: 0px !important;">
    <div class="q-section q-has-shaded-bg">
      <!--Main tile-->

      <div class="q-layout-container" style="padding-top: 0px !important; padding-bottom: 8px !important">
        <div class="q-layout-row padding-top-0 padding-bottom-0  ">
          <div
            class=" padding-top-0 padding-bottom-0  q-col-lg-12 q-col-md-12 q-col-xl-12 q-col-sm-12 q-display-flex-column q-flex-center-items q-align-self-center q-theme-text-container"
            style="background-color: transparent">
            <h1 class="q-center-h2 center-text" style="">
              Quotes
            </h1>


          </div>

        </div>
        <div class="q-layout-row  padding-top-0  padding-bottom-0">
          <div
            class="   padding-bottom-0 q-col-lg-6 q-col-md-6 q-col-xl-6 q-col-sm-12 q-display-flex-column q-align-stretch q-flex-center-items q-center"
            style="background-color: transparent">


            <div class="quote-container q-light-bg-container q-display-flex-column  q-center flow-center">
              <p>“If well organized, MFIs and SACCOs
                are the back bone of financial inclusion in Africa.”
                Joseph Lukula, C.E.O Nexen Tech 2024.</p>

            </div>
          </div>
          <div
            class="   padding-bottom-0 q-col-lg-6 q-col-md-6 q-col-xl-6 q-col-sm-12 q-display-flex-column q-align-stretch q-flex-center-items q-center"
            style="background-color: transparent">
            <div class="quote-container q-light-bg-container q-display-flex-column  q-center flow-center">
              <p>With Nexen,
                “Finance is exciting for everyone!”</p>

            </div>
          </div>
        </div>
      </div>
      <!--controls-->
    </div>
  </section>

  <BookADemoSection></BookADemoSection>

  <FooterComponent></FooterComponent>
</template>
<script>
import CustomFeatureComponent from './../components/CustomFeatureComponent.vue'
//import FeatureCardComponent from './../components/FeatureCardComponent.vue'
import FaqsComponentSection from './../components/FaqsComponentSection.vue'
import FooterComponent from './../components/FooterComponent.vue'
import BookADemoSection from './../components/BookADemoSection.vue'
import $ from 'jquery'

var tabTimer
const timerInterval = 4000
var currentTabItem = 0
export default {
  components: {
    CustomFeatureComponent,
    // FeatureCardComponent,
    FaqsComponentSection,
    FooterComponent,
    BookADemoSection
  },
  mounted: function () {

    this.showCurrentElement('#gw_feature_item-0')
  },
  data() {
    return {
      unions: [
        {
          tabName: 'SACCOs',
          url: './images/bridge_bidding_brown.webp',
          isActive: true,
          alt: 'bridge bidding brown'
        },
        {
          tabName: 'Individual',
          url: './images/theme.webp',
          isActive: false,
          alt: 'theme screen shot'
        }
      ],
      testimonials: [
        {
          key: 'key',
          description:
            'Yes, proudly! Nexen’s system is developed by Ugandan software developers. The intellectual property and rights to our system are wholly owned by Nexen.',
          title: 'Is the Nexen system Ugandan?'
        },
        {
          key: 'key',
          description:
            'Nexen connects members to thier SACCO and Microfinance accounts. They can save, apply for loans and repay digitally. Check balances and statements.',
          title: 'How do our members benefit?'
        },
        {
          key: 'key',
          description:
            'The data is hosted on AWS, a premier cloud hosting platform used and trusted globally as well as in Uganda. The cloud hosting at AWS complies with the Data Protection Act of Uganda.',
          title: 'Where is the data stored?'
        },

      ]
    }
  },
  methods: {
    preventReloadingPage: function (event) {
      event.preventDefault()
    },
    resetTimer: function () {
      // reset automatic tab selection timer
      clearInterval(tabTimer)
      tabTimer = setInterval(() => {
        // this.nextTab(null, false)
      }, timerInterval + 20)
    },
    selectTab: function (event) {
      // reset automatic tab selection timer
      this.resetTimer()
      // set current tab
      var element = Number(event.currentTarget.getAttribute('data-nav-index'))
      this.hideCurrentElement('#gw_feature_item-' + currentTabItem)
      this.unions[currentTabItem].isActive = false
      this.unions[element].isActive = true
      currentTabItem = element
      this.showCurrentElement('#gw_feature_item-' + currentTabItem)
    },

    nextTab: function (event, reset) {
      if (reset !== false) {
        this.resetTimer()
      }

      this.hideCurrentElement('#gw_feature_item-' + currentTabItem)
      currentTabItem = this.increamentElementIndex(this, currentTabItem)
      // current tab has changed
      this.showCurrentElement('#gw_feature_item-' + currentTabItem)
    },
    previousTab: function () {
      this.resetTimer()
      this.hideCurrentElement('#gw_feature_item-' + currentTabItem)

      currentTabItem = this.decreamentElementIndex(this, currentTabItem)
      // current tab has changed
      this.showCurrentElement('#gw_feature_item-' + currentTabItem)
    },
    increamentElementIndex: function (this_, indexTracker) {
      this_.items[indexTracker].isActive = false
      indexTracker = indexTracker + 1

      if (indexTracker >= this_.items.length) {
        indexTracker = 0
      }

      this_.items[indexTracker].isActive = true

      return indexTracker
    },
    decreamentElementIndex: function (this_, indexTracker) {
      this_.items[indexTracker].isActive = false

      indexTracker = indexTracker - 1

      if (indexTracker < 0) {
        indexTracker = this_.items.length - 1
      }

      this_.items[indexTracker].isActive = true

      return indexTracker
    },
    hideCurrentElement: function (id) {
      // hide current feature image

      $(id).addClass('q-fade-in-bottom-Lw')
      $(id).removeClass('q-show')
      setTimeout(() => {
        $(id).removeClass('q-feature-image-slide-element-current')
      }, 500)
    },
    showCurrentElement: function (id) {
      //  show current feature image

      setTimeout(() => {
        $(id).addClass('q-feature-image-slide-element-current')

        setTimeout(() => {
          $(id).removeClass('q-fade-in-bottom-Lw')
          $(id).addClass('q-show')
        }, 200)
      }, 500)
    }
  }
}
</script>
<style scoped>
.quote-container {
  background-color: red;
  border-radius: 16px;
  padding: 32px;
  min-height: 170px;
}

.quote-container p {
  text-align: center;
}

.hero-container {
  padding-right: 20px;
}

.hero-img {
  width: 100%;
  z-index: 9999;
}

.background-montage {
  position: absolute;
  max-height: 42px;
  width: 600px !important;
  top: -30px;
  left: -5%;
}

.q-feature-container {
  padding: 0 !important;
}

.q-feature-container .feature {
  padding: 16px !important;
}

.q-tabs ul {
  width: 100%;
}

.q-tabs-list li {
  padding: 32px 60px;
  width: fit-content;
}

.model-img {
  width: 70%;
}
</style>
