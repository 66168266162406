<template>
    <div class="q-animatable  q-sub-match-block  q-display-flex-column q-center q-feature-card"
        :class="[themeClass, rootClass]">
        <aside class="q-display-flex-column q-center q-flex-center-items">
            <img :src="`../images/${icon}`" alt="nexen-icons">
        </aside>
        <h3><strong>{{ title }}</strong></h3>
        <p>{{ description }}</p>
        <!-- <span ref="dirt" class="feature-bg q-dirt q-animatable">
            <i :class="icon"></i>
        </span> -->
    </div>
</template>
<script>
export default {
    name: 'CustomFeatureComponent',
    props: ['icon', 'title', 'description', 'themeClass', "rootClass"],
    mounted: function () {

        // var data = ['q-swing', 'q-shake', 'q-throw', 'q-entice']
        // var anim = Math.floor(Math.random() * (data.length - 0 + 1)) + 0

        // this.$refs.dirt.classList.add(data[anim])
    },
    methods: {
        add: function () {
            return ""
        }
    }
}
</script>
<style scoped>
.q-sub-match-block {
    padding: 42px;
    border-radius: 32px;
    border: 1px solid rgb(0, 0, 0, .12);

    min-height: 500px;
    overflow: hidden;
}

.q-feature-card {
    width: 100%;
}

.q-center-content {
    -webkit-align-items: center !important;
    align-items: center !important;
    text-align: center;

}

.q-center-content h3 {
    width: 90% !important;

}

.q-center-content p {
    text-align: center !important;

}

.q-sub-match-block p {
    text-align: left;
    color: white !important;
}

.q-sub-match-block h3 {
    margin-bottom: 0;
    color: var(--secondary_color) !important;
    letter-spacing: -1px;
}

.q-sub-match-block aside {
    width: 56px !important;
    height: 56px !important;


}

aside {
    border: 0;
    /* background: linear-gradient(var(--gw_primary_color), transparent) !important; */

    color: var(--secondary_color) !important;
}

.q-is-dark {
    background-color: #021d15 !important;
}

.q-is-dark-green {
    background-color: #021d15 !important;
}

.q-is-Light {
    background-color: white !important;
}

/* .q-is-Light aside {
    background-color: #eef9ee;
} */

/* .q-is-dark aside,
.q-is-dark-green aside {
    background-color: #0c372a;
} */

h3 {
    font-size: 1.5em !important;
}


.q-is-Light p,
.q-is-Light i {
    color: var(--gw_text_color) !important;
}

.q-is-dark-green h3,
.q-is-dark-green p,
.q-is-dark-green i,
.q-is-dark h3,
.q-is-dark p,
.q-is-dark i {
    color: white important;
}

aside i {
    font-size: 1.5em;
    color: white !important;
}

.feature-bg {
    position: absolute;
    background-color: transparent;
    left: 50%;
    top: 0;
    transform: rotate(30deg);
    width: fit-content;
}

.feature-bg i {
    font-size: 30em;
    color: #a0aaa0;
    opacity: 0.05;
}

@media (min-width: 992px) {
    .q-sub-match-block {
        padding: 42px;
        border-radius: 32px;
        height: 500px;
        overflow: hidden;
    }

    .q-center-content h3 {
        width: 60% !important;

    }
}
</style>
