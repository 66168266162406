<template>
  <div class="q-layout-row  padding-top-0">
    <div
      class=" padding-all-0  q-col-lg-12 q-col-md-12 q-col-xl-12 q-col-sm-12 q-display-flex-column q-align-stretch q-flex-center-items q-center"
      style="background-color: transparent">
      <h1 class="q-landing-page-sub-header">{{ title }}</h1>
    </div>
  </div>
  <div class="q-layout-row" style="border-radius: 64px; padding: 0px">
    <span class="q-dirt q-swing q-animatable" id="q-arch-triangle"
      style="width: 20px !important; top: 70%; left: -5%; height: auto">
      <img src="./../assets/images/dirt-spiral.svg" alt="dirt" style="width: 40px" />
    </span>
    <span class="q-dirt q-entice q-animatable" id="q-arch-triangle"
      style="width: 30px !important; left: 90%; top: 0%; height: auto">
      <img src="./../assets/images/dirt-square.svg" alt="dirt" style="width: 70px" />
    </span>

    <div class="q-col-12 q-display-flex-column q-flex-center-items q-center padding-top-0 "
      style="background-color: transparent">
      <FaqComponent v-for="tabItem in faqs" v-bind:key="tabItem.key" :description="tabItem.description"
        :title="tabItem.title"></FaqComponent>
    </div>
  </div>
</template>
<script>
import FaqComponent from './../components/FaqComponent.vue'

export default {
  name: 'FaqsComponentSection',
  props: { 'faqs': Array, title: String },
  components: {
    FaqComponent
  },
  data() {
    return {

    }
  },

}
</script>
<style scoped>
.q-feature-nav-button {
  width: 64px !important;
  height: 64px !important;
}

.q-testmony-current {
  display: flex !important;
}
</style>
