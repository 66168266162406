<template>
  <footer>
    <div class="q-layout-container  q-footer-legal-container" style="margin-bottom: 0;">
      <div class="q-section">
        <div class="q-layout-row">
          <div class="q-col-sm-12 q-col-md-12 q-col-lg-4 q-col-lg-4 q-footer-logo-container ">
            <a href="/"> <img src="./../assets/images/nexentech_logo.png" alt="nexentech logo" /></a>
            <p style="display: block; margin: auto;padding-left: 0px;padding-top: 16px;max-width: fit-content;">Powered
              by Nexen Tech</p>
            <span class="social-container q-display-flex-row q-center flow-center">
              <a href="#" target="_blank" class=" q-display-flex-row q-center flow-center"> <i
                  class="ri-facebook-fill"></i></a>
              <a href="https://linkedin.com/company/nexen-tech" target="_blank"
                class=" q-display-flex-row q-center flow-center"><i class="ri-linkedin-fill"></i></a>
              <a href="https://x.com/nexentech1" target="_blank" class=" q-display-flex-row q-center flow-center"><i
                  class="ri-twitter-x-line"></i></a>
            </span>
          </div>
          <div class="q-col-sm-12 q-col-md-12 q-col-lg-8 q-col-lg-8 q-footer-control-wrapper q-align-stretch">


            <div class="q-footer-control-item q-display-flex-column q-center q-flex-start">
              <h4>Why Nexen Tech</h4>
              <ul>

                <li><a href="/why-nexen#mission">Mission</a></li>
                <li><a href="/why-nexen#vision">Vision</a></li>
                <li><a href="/why-nexen#competences">Core competencies</a></li>
              </ul>
            </div>
            <div class="q-footer-control-item q-display-flex-column q-center q-flex-start">
              <h4>Company</h4>
              <ul>
                <li><a href="/about">About</a></li>
                <li><a href="/blog">Blog</a></li>
                <li><a href="/contact-us">Contact</a></li>
              </ul>
            </div>
            <div class="q-footer-control-item q-display-flex-column q-center q-flex-start">
              <h4>Contact</h4>
              <ul>
                <li><span>Uganda <br>
                    <br>
                    Plot 99 <br> <br>
                    Matyrs way Ntinda <br> <br>
                    Kampala-Uganda.
                    <br> <br>
                    0200940921

                  </span></li>

              </ul>
            </div>

          </div>
        </div>
        <div class="q-layout-row" style="padding: 0;">
          <div class="q-col-12 q-legal-container  q-display-flex-row" style="padding: 0;">
            <a href="#" class="legal">Terms and Conditions
            </a>
            <a href="#" class="legal" style="">Privacy Policy</a>
            <h4 class="legal" style="margin-top: 0px;">© 2024. NexenTech. All rights reserved.</h4>
          </div>

        </div>
      </div>
    </div>
    <div class="q-section">
      <hr class="q-hr" />
    </div>
    <div class="q-layout-container q-footer-legal-container ">
      <div class="q-section">
        <div class="q-layout-row">
          <div class="q-col-12" style="padding: 0;">

          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'FooterComponent',
  props: ['description', 'title'],
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle: function () {
      console.log('is toggedl')
      var openIcon = 'ri-arrow-down-s-line'
      var closeIcon = 'ri-arrow-up-s-line'
      this.isOpen = !this.isOpen
      this.$refs.content.style.display = this.isOpen ? 'flex' : 'none'
      if (this.isOpen) {
        this.$refs.icon.classList.remove(openIcon)
        this.$refs.icon.classList.add(closeIcon)
      } else {
        this.$refs.icon.classList.add(openIcon)
        this.$refs.icon.classList.remove(closeIcon)
      }
    }
  }
}
</script>
<style scoped>
.social-container {
  width: 100%;
  background-color: transparent;
  margin: auto;

}

.social-container a {
  color: var(--gw_text_color);
  padding: 8px;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  background-color: #eef9ee;
  margin: 8px;
}

.q-footer-control-item h4 {}

.legal {
  font-size: .8em;
  line-height: 1.5em;
  color: rgb(128 128 128/1);
  margin-left: 0px
}

.q-faq-header,
.q-faq-container {
  width: 100%;
}

.q-faq-header h3 {
  width: 95%;
  font-size: 1.5em;
  font-family: main_font_bold;
}



.q-faq-header {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--gw_text_color);
}

.q-faq-header span {
  width: 5%;
  height: 100%;
}

.q-faq-header span i {
  font-size: 1.5em;
}

.q-faq-content-section {
  height: auto;
}

.q-footer-control-item {

  max-width: 100%;
}

.q-footer-control-item ul {
  margin: 0px 0px 0 0;
}

.q-footer-control-item ul li {
  text-align: center
}

.q-footer-control-item h4 {
  margin-bottom: 1px !important;
  text-align: center;
}

.q-legal-container {
  flex-flow: column;
  text-align: center;
}

.q-legal-container .legal {
  margin-bottom: 8px;
}

@media (min-width: 768px) {
  .legal {
    margin-left: 32px
  }

  .q-footer-control-item {

    max-width: 50%;
  }

  .q-footer-control-item ul {
    margin: 0px 28px 0 0;

  }

  .q-footer-control-item ul li {
    text-align: left
  }

  .q-footer-control-item h4 {
    margin-bottom: 16px !important;
    text-align: left
  }

  .q-legal-container {
    flex-flow: row;
    text-align: left
  }

  .q-legal-container .legal {
    margin-bottom: 0px;
  }
}
</style>
